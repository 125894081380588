.container {
  width: 100%;
  z-index: 1;
}

/* MOBILE */
@media only screen and (max-width: 768px) {
  .container {
    padding-top: 84px;
  }
}
  
