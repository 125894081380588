.container {
  width: 100%;
  z-index: 1;
}

.containerMax {
  width: 100%;
  max-width: calc(1146px + 2rem);
  margin: auto;
  padding: 4rem;
  box-sizing: border-box;

  font-family: 'Roboto';
}

.container p {
  color: #5C656B;
  font-size: 1.25rem;
  line-height: 1.75rem;
  text-align: justify;
  font-family: 'Roboto';
}

.container h1 {
  color: #170F49;
  font-size: 36px;
}

.container h2 {
  color: #170F49;
  font-size: 30px;
}

.container h3 {
  color: #170F49;
  font-size: 24px;
}

.container a {
  color: #108B54;
}

 /* MOBILE */
@media only screen and (max-width: 768px) {
  .container {
    padding-top: 84px;
  }
}